import React from "react";
import { Grid } from "@material-ui/core";
import "../css/terms.css";
import logo from "../assets/images/logo.png";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";

const CookiePolicy = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 720px)" });
  const location = useLocation();
  const { contact } = location.state || {}; // state içinden texts prop'unu al

  return (
    <div className="div1" style={{ color: "white" }}>
      <center>
        <div class="mainLogoDivHeader">
          <a href="/">
            <img
              src={logo}
              alt="logo"
              style={{
                width: isMobile ? "50%" : "10%",
                objectFit: "cover",
                marginTop: "10px",
              }}
            />
          </a>
        </div>
      </center>

      <Grid container spacing={3} alignItems="center">
        <Grid item xs={1} sm={1} md={1} />
        <Grid item xs={10} sm={10} md={10}>
          <br></br>
          <br></br>
          <center>
            <h1>Pati Game Studio</h1>
            <h1>StepMate!</h1>

            <br></br>
            <u>
              <p>
                <strong>COOKIE POLICY</strong>
              </p>
            </u>
            <br></br>
          </center>

          <p>
            We use cookies so that you can use our website (www.stepmate.app)
            efficiently and to improve your user experience.
          </p>
          <br />
          <p>
            When you log in to our website, you are asked a question about
            whether you accept the use of Cookies. Cookies will be active after
            you choose the &apos;Accept&apos; option. If you do not prefer the
            use of cookies, you can always delete / block cookies in your
            browser settings. However, blocking cookies may affect the use of
            the site. The use of cookies is not limited to any period of
            time.&nbsp;
          </p>
          <br />
          <p>
            You share some information and data with us directly or indirectly
            for the use of the content on our website. This is the personal
            information you provide when registering (such as name, e-mail
            address, age, gender, telephone) or the information we collect
            during your use of the site (use of the contact form, comments,
            etc.) (data such as your IP information, device, location and
            browser information used, navigation and interaction content, date
            and connection time). We take care to store your information and
            data. Our website does not share your Cookie data stored on our web
            server with third parties. Your Cookie data stored by external
            services (Live Support plugin, Facebook plugin, YouTube video,
            Twitter content, etc.) are the responsibility of the external
            services.
          </p>
          <br />
          <p>
            <strong>What is a Cookie</strong>
          </p>
          <p>
            Websites can save information on your tablets, computers, phones and
            other devices you use, including but not limited to tablets, and
            make use of this information when you visit the same website. The
            files in which this small information is saved are called cookies.
            Cookies are used to improve your browsing experience and to
            personalize websites. For example, if you select the &quot;remember
            me&quot; option when logging in to a website, that site saves a
            cookie containing your username (etc.) in your browser. Cookies can
            only be read by the website that created them. The website
            determines the storage period.
          </p>
          <br />
          <p>What kind of data is processed in cookies?</p>
          <p>
            Cookies on websites, depending on the type of data collection,
            collect data about your usage preferences on the device you visit
            the site. This data includes information about the pages you visit,
            the services and products you view, your preferred browser, language
            option and other preferences.
          </p>
          <br />
          <p>
            <strong>Why are Cookies Used?</strong>
          </p>
          <p>
            To improve the service offered to you in order to make the Website
            more useful and to increase its performance,
          </p>
          <p>
            Improving the website by personalizing it according to your
            preferences, preparing additional features
          </p>
          <p>
            To ensure the commercial and legal security of the Website and our
            company, to prevent fake links
          </p>
          <p>
            To fulfill the requirements of the legal legislation (In particular;
            Law No. 5651 on the Regulation of Publications on the Internet and
            Combating Crimes Committed through These Publications and Regulation
            on the Procedures and Principles for the Regulation of Publications
            on the Internet, etc.)
          </p>
          <br />
          <p>
            <strong>TYPES OF COOKIES</strong>
          </p>
          <br />
          <p>
            <strong>Session Cookies</strong>
          </p>
          <p>
            Temporary cookies (non-permanent) are cookies that are active during
            the session, deleted at the end of the session, and prepared for the
            correct and reliable operation of the web page.
          </p>
          <br />
          <p>
            <strong>Persistent Cookies</strong>
          </p>
          <p>
            Persistent cookies that are used to remember you the next time you
            visit the website. They are stored in your browser until you delete
            them from your browser.&nbsp;
          </p>
          <br />
          <p>
            <strong>Performance Cookies</strong>
          </p>
          <p>
            It is used to improve the performance of the website and the comfort
            of use.
          </p>
          <br />
          <p>
            <strong>Functional Cookies</strong>
          </p>
          <p>
            Cookies that remind the visitor of previously made choices for the
            convenience of the visitor.&nbsp;
          </p>
          <br />
          <p>
            <strong>Analytical Cookies</strong>
          </p>
          <p>
            These cookies are used to collect and analyze how often the pages
            are used, at what times they are used, and data about the user.
          </p>
          <br />
          <p>
            <strong>Advertising and Third Party Cookies</strong>
          </p>
          <p>
            These cookies are cookies belonging to third party suppliers and, if
            used, allow the use of some content on the website and ad tracking
            (displaying ads that are suitable for the user, appealing to the
            user).
          </p>
          <p>
            The cookies used by our Websites other than the mandatory and first
            party ones are as follows:
          </p>
          <br />
          <p>
            <strong>Google Analytics</strong>
          </p>
          <p>
            It is a statistics tool provided by Google.&nbsp;&nbsp;It allows the
            website administrator to analyze user kits. Collects personal
            information such as first name, last name, phone number, ip address,
            etc. along with general anonymous information and sends it to Google
            to be recorded
          </p>
          <br />
          <p>
            <strong>Can the Use of Cookies be Blocked?</strong>
          </p>
          <p>
            You can delete/stop cookies, which are accepted automatically by
            most of your browsers or with your consent, through the settings of
            your browser. However, since many cookies, as explained above, serve
            the proper functioning of the website, the operation of the website
            may be affected by the deletion of cookies.&nbsp;
          </p>
          <p>
            Below is information on what steps should be followed to prevent the
            use of cookies through some internet browsers. As the instructions
            may vary, you can find the current steps in the help menu of your
            respective browser
          </p>

          <br />

          <p>
            <strong>- Internet Explorer</strong>
          </p>
          <p>
            Open your computer desktop and tap or click on the &quot;Internet
            Explorer&quot; icon in the taskbar.
          </p>
          <p>
            Click on the &quot;Tools&quot; button, then click on &quot;Internet
            options&quot;.
          </p>
          <p>
            Tap or click on the &quot;Privacy&quot; tab, then move the slider
            under the settings upwards to block all cookies and click
            &quot;OK&quot;.
          </p>
          <br />
          <p>
            <strong>- Microsoft Edge</strong>
          </p>
          <p>
            In the upper right corner of your &quot;Microsoft Edge&quot;
            browser, click on the section with the three dots and go to the
            &quot;Settings&quot; section.
          </p>
          <p>
            In the new window that appears, select &quot;Select Items to
            Clear&quot; and then select the cookies you want to clear from the
            window that appears.
          </p>
          <p>
            There are many sections here. You can start the cleaning process by
            selecting the cookies you want
          </p>

          <br />

          <p>
            <strong>- Google Chrome</strong>
          </p>
          <p>Open Google Chrome on your computer.</p>
          <p>Click on &quot;More Settings&quot; at the top right.</p>
          <p>Click on &quot;Advanced&quot; at the bottom.</p>
          <p>
            Under &quot;Privacy and Security&quot;, click on &quot;Content
            Settings&quot;.
          </p>
          <p>Then click on &quot;Cookies&quot;.</p>
          <p>
            Under &quot;All cookies and Site Data&quot;, look for the name of
            the website you are logged in to.
          </p>
          <p>Click the &quot;Remove&quot; icon to the right of the site.</p>
          <br />

          <p>
            <strong>- Mozilla Firefox</strong>
          </p>
          <p>
            From your &quot;Firefox&quot; browser, click on the &quot;Menu&quot;
            button and go to &quot;Options&quot;.
          </p>
          <p>
            Select the &quot;Privacy and Security&quot; panel and go to the
            &quot;History&quot; section.
          </p>
          <p>
            Change the Firefox setting to &quot;use custom settings for
            history&quot;.
          </p>
          <p>
            Click on the &quot;Show cookies&quot; button. The cookies window
            will appear.
          </p>
          <p>
            In the search field, type the name of the website whose cookies you
            want to delete. Cookies that match your search with the website will
            be displayed.
          </p>
          <p>
            Select the cookie(s) you want to delete and click on the
            &quot;Delete Selected&quot; button.
          </p>
          <p>
            Close the cookies window by clicking on the &quot;Close&quot;
            button. Then close the &quot;about:preferences&quot; page.
          </p>

          <br />

          <p>
            <strong>- Safari</strong>
          </p>
          <p>
            Enter the &quot;Safari&quot; browser and go to
            &quot;Preferences&quot;.
          </p>
          <p>Click on &quot;Privacy&quot;.</p>
          <p>Click on &quot;Website Data&quot;.</p>
          <p>
            Select one or more websites and then click &quot;Delete or Delete
            All&quot;.
          </p>
          <br />
          <p>
            <strong>Contact us</strong>
          </p>
          <p>
            You can send an e-mail to {contact} to send all your questions and
            comments.&nbsp;
          </p>
          <br />
          <p>
            <strong>Data Retention and Destruction Policy</strong>
          </p>
          <p>
            You can send an e-mail to {contact} for the destruction of your
            stored personal data.
          </p>

          <br></br>
          <br></br>
          <br></br>
        </Grid>

        <Grid item xs={1} sm={1} md={1} />
      </Grid>
    </div>
  );
};

export default CookiePolicy;
