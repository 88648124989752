import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import "../css/terms.css";
import logo from "../assets/images/logo.png";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";

const Faq = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 720px)" });

  const location = useLocation();
  const { contact } = location.state || {}; // state içinden texts prop'unu al

  return (
    <div
      className="div1"
      style={{ color: "white", paddingTop: isMobile ? "70px" : "5px" }}
    >
      <center>
        <div class="mainLogoDivHeader">
          <a href="/">
            <img
              src={logo}
              alt="logo"
              style={{
                width: isMobile ? "50%" : "10%",
                objectFit: "cover",
                marginTop: "10px",
              }}
            />
          </a>
        </div>
      </center>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={1} sm={1} md={1} />
        <Grid item xs={10} sm={10} md={10}>
          <br></br>
          <br></br>
          <center>
            <h1>Pati Game Studio</h1>
            <h1>StepMate!</h1>
            <br></br>

            <u>
              <p>
                <strong>Frequently Asked Questions</strong>
              </p>
            </u>
            <br></br>
          </center>
          <p>
            <ul>
              <li>1. What is the Step Mate app?</li>
              <li>
                It is an application where you can both follow your steps on a
                daily basis and convert your steps into SMP (Step Mate Points)
                by watching ads. With the converted SMPs, you can receive
                constantly updated rewards from our store!
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>2. Is the Step Mate app free?</li>
              <li>Yes, the app is entirely free to download and use.</li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>3. How do I create an account?</li>
              <li>
                When you open the app, you can log in with your Google account.
                If you have an invitation code, log in by adding this code, then
                the SMP reward (Invite & Earn Bonus) will be defined for you and
                your friend who invited you as soon as you convert your first
                steps.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>4. What is SMP?</li>
              <li>
                Step Mate! Point is an in-app currency that you earn when you
                convert daily steps and make purchases in the store.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>5. How do I collect SMP?</li>
              <li>
                You can convert the steps you take during the day by watching
                ads. The more you walk, the more you earn Step Mate! Points! But
                please remember that the daily limit is 20,000 steps that you
                can convert.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>6. What can I do with SMP?</li>
              <li>
                You can buy anything you choose from our store with Step Mate!
                Points. Don't forget to check the store regularly; it's always
                updated!
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>7. Can I convert SMP into cash? </li>
              <li>
                Step Mate! Points cannot be converted into cash. It is only used
                to purchase in-app of rewards.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>8. What is the validation period for my earned SMP? </li>
              <li>
                Validation time is 1 year starting from the time you join Step
                Mate! Every membership anniversary, your unused Step Mate!
                Points will be deleted and reset.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>9. Can I transfer my SMP to another account? </li>
              <li>
                Step Mate! Points cannot be transferred to another account. All
                points are account based.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>10. How do my steps turn into rewards? </li>
              <li>
                When you want to convert your steps, you can watch an ad by
                clicking the “Convert Steps” button. Once the ad is finished,
                your steps will be transformed into Step Mate! Points. And with
                those points you can go to the rewards section and claim your
                gift!
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>11. Can I convert my steps whenever I want? </li>
              <li>
                You can convert your steps by watching ads anytime during the
                day. But your daily steps will reset at 12:00pm. So, you have
                time until 11:59pm.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>12. What to do when my Energy is low? </li>
              <li>
                As you walk, your energy gets lower. By clicking on the
                'lightning icon' on the main screen, you can increase your
                energy and get the highest multiplier for your steps.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>13. How can I receive products? </li>
              <li>
                After converting your steps, you can select any product from the
                store with the Step Mate! Points specified in your account.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>14. How are the products shipped? </li>
              <li>
                We send the actual products by Blu Ex Courier company. If you
                choose a digital code, you can find the details within the
                “Rewards” section in the app.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>15. Do I pay the delivery fee for the product I choose? </li>
              <li>
                No. Step Mate covers the delivery cost of all the rewards you
                choose. Only exception is; if the courier company can not
                deliver the product cause you are not present at the adress, the
                product returns to us. Afterwards, you should re-send the
                product by pressing the “Re-send” button in the “Rewards”
                section. But this 2nd time, the payment will be on your side.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>16. How long should I wait for a product to ship? </li>
              <li>
                The approval of purchased digital codes takes place within 48
                hours. Regular products are sent to the address you gave within
                7 working days. Yo can tack this through progress from the
                “Rewards” section in the app.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>17. Is there any way I can earn more SMP? </li>
              <li>
                Apart from converting your daily steps and completing bonuses,
                when your friends log into the app with your invitation code and
                convert their first steps, you both will earn rewards.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>18. What bonuses are in Step Mate? </li>
              <li>
                There are a lot of bonuses in Step Mate app. Some of them are;
                Regular Bonus which requires your 7 days of consecutive log in;
                daily Watch & Win Bonus and there are 1,000, 3,000, 5,000,
                10,000, and 20,000 Step Bonuses that you can earn Step Mate!
                Points as you complete the required steps.{" "}
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>19. Can I add my friends? </li>
              <li>
                You can add friends by clicking on the profile link of your
                friends or by searching for your friends in the search button.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>20. Can I invite my friends? </li>
              <li>
                Yes! When your friend joins Step Mate with your invitation code
                and converts their first steps, you both will get rewards
                (Invite & Earn Bonus).
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>21. Does Step Mate app use extra battery power? </li>
              <li>
                Step Mate does not use extra battery power as it accesses your
                step information from the Apple Health or Google Fit apps.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>22. Can I use the app with my smartwatch? </li>
              <li>
                If your smartwatch is compatible with Apple Health or Google
                Fit, Step Mate can access the steps you take during the day
                through these applications.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>23. Why can't I watch ads? </li>
              <li>
                Ads within the app are shown by ad providers. There may be
                issues with displaying ads during times when users are heavily
                watching ads. To avoid such a problem, we recommend that you
                convert your steps outside of the rush hours (the end of the day
                is the busiest time). If this problem is not for couple of
                minutes and you can never watch ads, we suggest that you make
                sure not to use a Proxy, VPN or Ad Blocker and your DNS settings
                are on DHCP mode in Android and automatic on IOS devices.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>24. Why Aren't My Points Loading? </li>
              <li>
                You may not have given the necessary permissions for Step Mate
                to see the step data. Check this by following the steps below.
                For iOS devices, go to Settings{">"}Privacy{">"}Health{">"}Step
                Mate from your phone. You need to turn on all permissions here.
                On Android devices, go to Settings{">"}Applications{">"}Step
                Mate{">"}Permissions from your phone. You need to turn on all
                permissions here.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>25. I can't watch ads. What should I do? </li>
              <li>
                Firstly, it should be noted that at times, it may take some time
                for our ad channels to deliver ads tailored to you.
                Occasionally, this period can be extended due to high demand. If
                you are experiencing this issue consistently, we kindly request
                the following from you: <br />- Remove any ad blockers, VPNs, or
                proxies from your phone, <br />
                - Ensure that the internet you are using is not from a
                restricted wireless network (such as a school or workplace),
                <br />
                - Verify that the DNS settings are set to DHCP on Android phones
                and automatic on iPhone phones,
                <br />- Ensure that the{" "}
                <a
                  href="https://play.google.com/store/apps/details?id=com.google.android.webview"
                  style={{ color: "#3366FF" }}
                >
                  Android System WebView
                </a>{" "}
                and
                <a
                  href="https://play.google.com/store/apps/details?id=com.google.android.gms"
                  style={{ color: "#3366FF" }}
                >
                  {" "}
                  Google Play Services
                </a>{" "}
                applications are up to date.
                <br />- After updating all your settings in this manner, please
                try again using a mobile network.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <ul>
              <li>26. Didn't find the answer you were looking for? </li>
              <li>
                Contact Us If you have a question that is not answered in the
                FAQ, you can contact the Step Mate support team by sending an
                e-mail to {contact}
              </li>
            </ul>
          </p>
          <br /> <br />
        </Grid>

        <Grid item xs={1} sm={1} md={1} />
      </Grid>
    </div>
  );
};

export default Faq;
