import React, { useState } from "react";
import "../../../css/body.css";
import UpperBody from "./UpperBody";
import MiddleBody from "./MiddleBody";
import BottomBody from "./BottomBody";

const Body = (props) => {
  return (
    <>
      <UpperBody texts={props.texts} />

      <MiddleBody texts={props.texts} />

      <BottomBody texts={props.texts} />
    </>
  );
};

export default Body;
