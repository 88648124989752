import React from "react";
import { Grid } from "@material-ui/core";
import "../css/terms.css";
import logo from "../assets/images/logo.png";
import { useMediaQuery } from "react-responsive";

const UserAgreementId = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 720px)" });

  return (
    <div className="div1" style={{ color: "white" }}>
      <center>
        <div class="mainLogoDivHeader">
          <a href="/">
            <img
              src={logo}
              alt="logo"
              style={{
                width: isMobile ? "50%" : "10%",
                objectFit: "cover",
                marginTop: "10px",
              }}
            />
          </a>
        </div>
      </center>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={1} sm={1} md={1} />
        <Grid item xs={10} sm={10} md={10}>
          <br></br>
          <br></br>
          <center>
            <h1>Pati Game Studio</h1>
            <h1>StepMate!</h1>

            <br></br>

            <u>
              <p>
                <strong>
                  <a href="/">PERJANJIAN PENGGUNA</a>
                </strong>
              </p>
            </u>
            <br></br>
          </center>

          <p>
            <strong>1-Pihak-pihak dan Subjek</strong>
          </p>
          <p>
            Perjanjian ini mengatur syarat dan ketentuan antara Pati Teknoloji
            Dış Ticaret A.Ş. (Pati) dan pengguna aplikasi Step Mate!, dan
            penggunaan aplikasi berarti bahwa perjanjian ini beserta
            lampirannya, pernyataan dan kondisi yang akan diungkapkan di masa
            depan, serta perubahan yang akan dibuat dan dipublikasikan dalam
            perjanjian ini diterima oleh pengguna dan bahwa pengguna
            berkewajiban untuk mengikuti perubahan-perubahan tersebut dan
            pemberitahuannya. Jika Anda tidak menerima ketentuan kontrak
            berikutnya, jangan menggunakan aplikasi ini.
          </p>
          <p>
            <br />
            <strong>2- Hak Penegakan</strong>
          </p>
          <p>
            Aplikasi dan semua kontennya adalah milik Pati. Tidak boleh disalin,
            digunakan tanpa izin, atau dipublikasikan, bahkan sebagian. Akses
            pengguna aplikasi ke aplikasi ini dibatasi pada waktu dan ruang yang
            diizinkan oleh perusahaan Pati. Semua hak aplikasi adalah milik
            perusahaan Pati. Perusahaan Pati bertujuan menjaga aplikasi tetap
            aktif tanpa dibatasi oleh jangka waktu tertentu. Perusahaan Pati
            dapat menghentikan penggunaan aplikasi secara sementara atau
            permanen karena masalah teknis, alasan hukum, atau alasan lainnya,
            termasuk tetapi tidak terbatas pada masalah teknis, alasan hukum,
            atau alasan lainnya, dan dapat menyerahkan semua hak dan kewajiban
            kepada pihak ketiga tanpa persetujuan pengguna; dalam hal ini,
            Perusahaan Pati tidak memiliki tanggung jawab terhadap pengguna.
          </p>
          <p>
            <br />
            <strong>3- Aktivasi Aplikasi</strong>
          </p>
          <p>
            Pengguna dapat menggunakan aplikasi setelah mengakses akun media
            sosial pribadinya. Pengguna menerima dan menyatakan bahwa alamat
            email dan nomor telepon yang dideklarasikan oleh pengguna adalah
            milik pribadi pengguna dan bahwa pengguna memberikan persetujuan
            untuk semua jenis pesan yang akan dikirimkan ke email dan/atau
            ponsel ini. Pengguna dapat mengubah email atau nomor telepon dan
            informasi pribadi lainnya melalui aplikasi. Juga, mungkin membuat
            permintaan perubahan melalui pemberitahuan tertulis ke alamat email
            contact@stepmate.app. Namun, Perusahaan Pati berhak untuk tidak
            melakukan perubahan yang diperlukan tanpa konfirmasi informasi
            pribadi anggota. Proteksi nama pengguna dan kata sandi sepenuhnya
            menjadi tanggung jawab pengguna, dan jika konfirmasi yang diperlukan
            tidak dapat dilakukan, akses ke akun yang terhubung dengan nama
            pengguna dan kata sandi yang hilang mungkin tidak dapat disediakan.
          </p>
          <br />
          <p>
            <strong>4- Ketentuan Umum dan Tanggung Jawab Pengguna</strong>
          </p>
          <p>
            Pengguna menerima dan berjanji bahwa selama menggunakan aplikasi, ia
            akan menggunakannya sesuai dengan semua hukum yang berlaku, terutama
            hukum kekayaan intelektual, dan dalam kerangka aturan yang
            ditetapkan/akan ditetapkan oleh perusahaan Pati. Segala jenis hak
            materi dan moral serta hak cipta aplikasi dan kontennya adalah milik
            perusahaan Pati. Pengguna setuju untuk tidak melanggar hak-hak
            tersebut secara langsung atau tidak langsung, bahkan sebagian, dan
            untuk mengganti kerugian ganti rugi dan kerugian pendapatan serta
            biaya peradilan, jika ada, yang harus dibayar oleh Perusahaan Pati
            kepada pihak ketiga karena alasan yang timbul dari penggunaan
            aplikasi, tanpa syarat, pada saat permintaan pertama. Akun tidak
            boleh dibuka dengan email palsu, nomor telepon, informasi pribadi.
            Akun tidak dapat disediakan kepada pihak ketiga. Anak di bawah usia
            18 tahun dapat menggunakan aplikasi dengan akun yang dibuka oleh
            wakil hukum/orang tua mereka jika diizinkan oleh hukum negara tempat
            mereka berada, dengan asumsi bahwa semua tanggung jawab berada pada
            wakil hukum/orang tua itu sendiri. Batas usia minimum adalah 18
            tahun dan anak-anak di bawah batas ini tidak dapat menggunakan
            aplikasi bahkan dengan persetujuan wakil hukum/orang tua mereka.
          </p>
          <br></br>
          <p>
            Tanggung jawab pengguna untuk mengakses aplikasi menggunakan
            perangkat dengan fitur perangkat keras dan perangkat lunak yang
            diperlukan untuk aplikasi bekerja. Pati dapat melakukan pembaruan
            yang memerlukan perangkat keras dan perangkat lunak yang lebih
            tinggi untuk meningkatkan aplikasi. Ini dapat mengubah aplikasi
            sebagian atau sepenuhnya.
          </p>
          <br></br>
          <p>
            Perusahaan Pati tidak bertanggung jawab atas data pribadi dan
            praktik privasi, konten, dan keamanan situs web yang diarahkan
            melalui aplikasi baik untuk perjanjian periklanan maupun alasan
            lainnya.
          </p>
          <br></br>
          <p>
            Tidak mungkin mengembalikan atau menolak hadiah, keuntungan, fitur
            tambahan, dll. yang diterima dari toko virtual dalam aplikasi dengan
            poin aplikasi (Step Mate! Points) atau metode pembayaran lainnya.
          </p>
          <br></br>
          <p>
            Pati tidak bertanggung jawab atas korespondensi antara pengguna dan
            kontennya. Jika korespondensi bertentangan dengan hukum dan aturan
            moral umum, akun pengguna terkait dapat ditutup sementara atau
            permanen.
          </p>
          <br></br>
          <p>
            Pengguna setuju bahwa Perusahaan Pati tidak bertanggung jawab atas
            kerugian yang disebabkan secara langsung atau tidak langsung oleh
            aplikasi, layanan yang ditawarkan, atau aplikasi melalui aplikasi
            kepada pengguna oleh pihak ketiga.
          </p>
          <br></br>
          <p>
            <strong>5- Data Pribadi dan Persetujuan Tegas Pengguna</strong>
          </p>
          <p>
            Dengan menggunakan aplikasi Step Mate!, pengguna menyetujui untuk
            mengizinkan penggunaan informasi pribadinya untuk tujuan yang
            ditetapkan di sini, termasuk tetapi tidak terbatas pada, memberikan
            kampanye umum dan personal, hadiah, keuntungan, dll. dalam hal
            pendaftaran dan/atau partisipasi dalam acara, menyelesaikan masalah
            yang mungkin timbul karena aplikasi atau alasan lainnya.
          </p>
          <p>
            Pengguna telah memberikan persetujuan untuk pemrosesan nama, nama
            belakang, dan informasi dalam bagian formulir informasi pribadi yang
            diisi di formulir yang relevan, lokasi dan alamat IP di jaringan
            internet, bidang terkait, dll. informasi, preferensi, baik untuk
            studi statistik umum maupun untuk mempersiapkan kampanye umum atau
            khusus, keuntungan, dan hadiah. Dalam konteks ini, dia tahu dan
            memungkinkan bahwa data digitalnya, langsung atau tidak langsung
            (termasuk informasi langkah) dapat dikumpulkan, disimpan selama yang
            diperlukan dalam batas hukum, dan di bawah tindakan keamanan siber.
          </p>
          <p>
            Pengguna telah memberikan persetujuan kepada Pati untuk berbagi data
            pribadinya dengan semua jenis penyedia layanan, pemasok, mitra
            bisnis, lembaga terkait, dan pihak ketiga dalam hal persyaratan
            hukum. Ini dapat mencakup yang berlokasi di luar negeri. Berbagi di
            sini dibatasi secara langsung sebanding dengan tujuan dan bertujuan
            untuk meningkatkan kualitas layanan yang diberikan dan memastikan
            pemenuhan layanan langsung. Pengguna juga telah memberikan
            persetujuan untuk berbagi data yang harus dibagikan sesuai dengan
            peraturan hukum dengan lembaga-lembaga terkait.
          </p>
          <br></br>
          <ul>
            <li>
              <strong>
                Hak Anda berdasarkan Pasal 11 Undang-Undang No. 6698
              </strong>
            </li>
          </ul>
          <p>
            Berdasarkan Pasal 11 Undang-Undang No. 6698; Setiap orang, dengan
            mengajukan permohonan kepada pengendali data;
            <ul>
              <li>a) Mengetahui apakah data pribadi diproses, </li>
              <li>b) Meminta informasi jika data pribadi telah diproses, </li>
              <li>
                c) TMengetahui tujuan pemrosesan data pribadi dan apakah
                digunakan sesuai dengan tujuannya,{" "}
              </li>
              <li>
                d) Mengetahui pihak ketiga yang menerima transfer data pribadi
                di dalam negeri atau ke luar negeri,
              </li>
              <li>
                e) Meminta koreksi data pribadi dalam hal pemrosesan yang tidak
                lengkap atau salah,{" "}
              </li>
              <li>
                f) RMeminta penghapusan atau penghancuran data pribadi dalam
                kerangka kondisi yang ditentukan dalam Pasal 7,
              </li>
              <li>
                g) Meminta pemberitahuan atas transaksi yang dilakukan sesuai
                dengan subpasal (d) dan (e) kepada pihak ketiga yang menerima
                transfer data pribadi,{" "}
              </li>
              <li>
                h) Melawan munculnya hasil merugikan bagi dirinya sendiri dengan
                menganalisis data yang diproses secara eksklusif melalui sistem
                otomatis,{" "}
              </li>
              <li>
                i) Dalam hal kerugian akibat pemrosesan data pribadi yang
                melanggar hukum, berhak menuntut ganti kerugian
              </li>
            </ul>
          </p>
          <br></br>
          <ul>
            <li>
              <strong>Persetujuan Tegas&nbsp;</strong>
            </li>
          </ul>
          <p>
            Pengguna telah memberikan persetujuan tegas untuk pengumpulan dan
            penyimpanan data pribadi untuk tujuan yang tercantum di atas,
            berbagi secara domestik dan internasional, meningkatkan dan
            mempersonalisasi layanan yang ditawarkan, digunakan untuk tujuan
            komersial, dan membuat pemberitahuan melalui pesan teks, surat,
            pesan suara, dan metode lainnya serta berbagi dengan pihak ketiga.
          </p>
          <br />
          <p>
            <strong>6- Pemutusan</strong>
          </p>
          <p>
            Jika Pengguna melanggar ketentuan Perjanjian sebagian atau
            seluruhnya, bagaimanapun juga, jika Perusahaan Pati menganggap
            perlu, dapat menghentikan sementara atau permanen pengguna dari
            menggunakan akun pengguna tanpa syarat apa pun dan dapat mengakhiri
            perjanjian secara sepihak tanpa perlu ada pemberitahuan.
          </p>
          <p>
            Jika dipahami bahwa hukum atau aturan yang ditetapkan oleh
            perusahaan Pati tidak dipatuhi, dapat membatalkan semua keuntungan
            dan juga dapat menuntut kembali keuntungan masa lalu pengguna.
          </p>
          <br />
          <p>
            <strong>
              7- Pengadilan yang Berkompeten dan Hukum yang Berlaku
            </strong>
          </p>
          <p>
            Semua sengketa akan diselesaikan di Pengadilan Negeri Istanbul
            sesuai dengan hukum Republik Turki.
          </p>
          <br />
          <p>
            <strong>8- Alamat Pemberitahuan</strong>
          </p>
          <p>
            Pemberitahuan ke alamat email yang ditentukan dalam akun pengguna
            dianggap telah dilakukan kepada pengguna dan tidak memerlukan
            pemberitahuan lebih lanjut. Pengguna harus menjaga agar alamat
            emailnya tetap aktif dan memberitahukan setiap perubahan. Jika
            tidak, bahkan jika pemberitahuan yang dikirim tidak sampai, dianggap
            telah dilakukan.
          </p>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </Grid>

        <Grid item xs={1} sm={1} md={1} />
      </Grid>
    </div>
  );
};

export default UserAgreementId;
