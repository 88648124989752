import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MainPage from "./pages/MainPage";
import InviteFriend from "./pages/InviteFriend/InviteFriend";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import CookiePolicy from "./pages/CookiePolicy";
import UserAgreement from "./pages/UserAgreement";
import Faq from "./pages/Faq";
import FaqInd from "./pages/FaqInd";
import PrivacyId from "./pages/PrivacyId";
import TermsId from "./pages/TermsId";
import UserAgreementId from "./pages/UserAgreementId";
import CookiePolicyId from "./pages/CookiePolicyId";
// import NotFoundPage from "./pages/NotFoundPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<MainPage />} />
        <Route path="/inviteFriend/:code" element={<InviteFriend />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/terms-id" element={<TermsId />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/privacy-id" element={<PrivacyId />} />
        <Route path="/cookiePolicy" element={<CookiePolicy />} />
        <Route path="/cookiePolicy-id" element={<CookiePolicyId />} />
        <Route path="/userAgreement" element={<UserAgreement />} />
        <Route path="/userAgreement-id" element={<UserAgreementId />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/faq-ind" element={<FaqInd />} />
        {/* <Route path="*" element={<NotFoundPage />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
